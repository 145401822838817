#home {
    position: relative;
    background: url('../../assets/bgIMG.png');
    z-index:0;
    background-size: cover;
    background-repeat: repeat;
    background-position: center;
    
    .app__wrapper {
        padding: 0;
        .copyright {
            display: none;
        }
    }
}


.app__header {
    flex:1;
    width: 100%;
    height: 100%;
    flex-direction: row;

    padding: 6rem 2rem 0;

    @media screen and (min-width: 2000px) {
        padding-top: 8rem;
    }
    @media screen and (max-width: 1200px) {
        flex-direction: column;
    }
    @media screen and (max-width: 400px) {
        padding: 6rem 1rem 2rem;
    }
}

.app__header-info {
    flex: 0.65;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
    @media screen and (max-width: 2000px) {
        width: 100%;
        margin-right: 0rem;    
    }
}
.app__header-badge {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

    .badge-cmp, .tag-cmp {
        padding: 1rem 2rem;
        border: var(--white-color);
        border-radius: 15px;
        flex-direction: row;
        width: auto;

        box-shadow: 0 0 20px rgba(0,0,0,0.1);
    }

    .badge-cmp {
        display: flex;
        flex-direction: row; /* Explicitly stating this, though it's the default */
        align-items: center;
    }
    .tag-cmp {
        flex-direction: column;
        margin-top: 3rem;

        p {
            width: 100%;
            text-transform: uppercase;
            text-align: right;
        }
    }

    span {
        font-size: 2.5rem;
        @media screen and (min-width: 2000px) {
            font-size: 5rem;
        }
    }

    @media screen and (max-width: 1200px){
        justify-content: flex-start;
        align-items: flex-start;
    }
}

.app__header-circles {
    flex: 0.75;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    height: 100%;
    margin-left: 1rem;

    div {
        width:100px;
        height:100px;
        border-radius: 50%;

        background: var(--white-color);
        box-shadow: 0 0 20px rgba(0,0,0,0.1);

        img {
            width: 60%;
            height: 60%;
        }
    }


    div:nth-child(1) {
        width: 95px;
        height: 100px;
    }
    div:nth-child(2) {
        margin: 1.75rem;
        width: 150px;
        height: 150px;
    }
    div:nth-child(3) {
        width: 75px;
        height: 70px;
    }


    
    @media screen and (min-width: 2000px) {
        div:nth-child(1) {
            width: 190px;
            height: 200px;
        }
        div:nth-child(2) {
            width: 300px;
            height: 300px;
        }
        div:nth-child(3) {
            width: 150px;
            height: 140px;
        }
    }

    @media screen and (max-width: 1200px) {
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: 0;

        div {
            margin: 1rem;
        }
    }
   
}

.app__header-img {
    flex: 1;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: relative;

    img {
        position: relative;
        bottom: 0; 
        left: 50%; 
        transform: translateX(-45%) translateY(-5%) scale(1.1); 
        height: auto; 
        max-height: 90%; 
        width: auto; 
        max-width: 100%; 
        z-index:1;
    }

    .overlay_circle{
        position: absolute;
        left: 0;
        right:0;
        bottom: 0;
        z-index:0;
        width: 100%;
        height: 90%;
    }

    @media screen and (max-width: 1200px) {
        margin: 2rem 0;
    }
}

.resume-download-button {
    padding: 10px 20px;
    margin-top: 20px; // Adjust spacing above the button as needed
    background-color: var(--white-color); // Use your theme's primary color
    color: var(--black-color); // Adjust text color as needed
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease, transform 0.3s ease;
    
    &:hover {
      background-color: var(--secondary-color);
      color: var(--white-color) // Darkens the button on hover
    }
  
    &:active {
      transform: scale(0.95); // Slight shrink effect to show it's been clicked
    }
  }
